import React from 'react';

import Icon from './Icon';

export default function DarkThemeIcon({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Dark theme">
      <svg
        className={className}
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9884 22.2371C24.1204 22.0998 23.9932 21.8788 23.8046 21.9062C23.3787 21.968 22.9431 22 22.5 22C17.5294 22 13.5 17.9706 13.5 13C13.5 10.9982 14.1535 9.14909 15.2589 7.6543C15.3722 7.50112 15.2373 7.28474 15.054 7.33641C11.2721 8.40203 8.5 11.8773 8.5 16C8.5 20.9706 12.5294 25 17.5 25C20.0492 25 22.3509 23.9402 23.9884 22.2371Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
